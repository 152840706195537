<template>
  <app-header />
  <Suspense>
    <template #default>
      <AppSubscribes />
    </template>
    <template #fallback>
      <app-loading />
    </template>
  </Suspense>
  <app-footer />
</template>

<script>
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppSubscribes from '../../components/Users/AppSubscribes.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from 'vue'
export default {
  components: { AppHeader, AppSubscribes, AppFooter, AppLoading, Suspense },
  name: 'Subscribes'
}
</script>

<style></style>