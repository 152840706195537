<template>
    <div class="login">
      <div class="container">
        <div class="row justify-content-center align-items-center d-flex vh-100">
          <div class="col-lg-5 mx-auto">
            <div class="osahan-login p-5 bg-dark">
              <div class="text-center mb-4">
                <router-link to="/"><img src="../../assets/image/yallagame.png" style="height: 50px;" alt=""></router-link>
                 <h5 class="font-weight-bold mt-3" style="color: #fff;"> اشترك الأن </h5>
              </div>
              <form  @submit.prevent="onLogin()">
                <div class="form-group">
                  <!-- <label class="mb-1" style="color: #fff;">رقم الهاتف</label> -->
                    <div class="position-relative icon-form-control">
                      <input type="text" v-model="msisdn" class="form-control" autocomplete="off" placeholder="رقم الهاتف" onfocus="this.placeholder = ''" onblur="this.placeholder = 'رقم الهاتف'" style="text-align: center;">
                    </div>
                </div>
                <!-- <button class="btn btn-warning btn-lg btn-block text-uppercase" type="submit">تسجيل الدخول</button> -->
                <button class="btn btn-warning btn-lg btn-block text-uppercase">
                    <span v-if="loading" class="px-1 arabickufi">أشتراك</span> &nbsp;
                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                    <span v-else class="arabickufi">أشتراك</span>
                </button>
                
              </form>
              <p class="font-weight-bold mt-3" style="color: #fff;font-size: 14px;padding-top: 4vh;">هذه الخدمة متوفرة لعملاء شركة الاتصالات السعودية مقابل 1.5 تتجدد يوميا (غير شامل قيمة الضريبة المضافة 15%) ولإلغاء الاشتراك الرجاء ارسال رسالة فارغة الى 802043.</p>
            <p class="font-weight-bold mt-3" style="color: #fff;font-size: 14px;">هذه الخدمة متوفرة لعملاء موبايلي مقابل 1.5 ريال تتجدد يوميا (غير شامل قيمة الضريبة المضافة 15%)    لإلغاء اشتراكك الرجاء ارسال غ١ الى 606793</p>
            <p class="font-weight-bold mt-3" style="color: #fff;font-size: 14px;">هذه الخدمة متوفرة لعملاء زين مقابل 1.5 ريال تتجدد يوميا (غير شامل قيمة الضريبة المضافة 15%) لإلغاء اشتراكك الرجاء ارسال غ١ الى 706027   </p>
            <p class="font-weight-bold mt-3" style="color: #fff;font-size: 14px;">*تم تحصيل مبلغ الضريبة لعملاء مسبق الدفع عند عملية شحن الرصيد</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { HTTPSPA } from '@/Api/http-Spay';
import { useToast } from "vue-toastification";
// import { ref } from 'vue';
// import { useRouter, useRoute } from "vue-router";
// import { useCookie } from 'vue-cookie-next'
export default {
  name: 'AppSubscribes',
  data() {
      return {
        msisdn: "",
        loading: false,
      };
    },
    methods: {
      async onLogin() {
        const toast = useToast();
        const Regex =  new RegExp(/^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/);

        if (this.msisdn === '') {
          toast.error("لا يمكن ترك حقل رقم الهاتف فارغًا");
      } else if (!Regex.test(this.msisdn)) {
          toast.error("رقم الهاتف غير صحيح");
      } else {
        if (this.msisdn.substring(0, 1) === "0" && this.msisdn.length === 10) {
          this.number = this.msisdn.substring(1);
        } else if (this.msisdn.substring(0, 1) === "5" && this.msisdn.length === 9) {
          this.number = this.msisdn.substring();
        } else if (this.msisdn.substring(0, 1) === "9" && this.msisdn.length === 12) {
          this.number = this.msisdn.substring(3);
        } else if (this.msisdn.substring(0, 1) === "0" && this.msisdn.length === 14) {
          this.number = this.msisdn.substr(5);
        }

        if (this.number.substring(2, 1) === '9') {
          // Zain api call to sent otp
          // console.log("zainnnnnnnnnnnnnnnnn")
          this.loading = true;
          await HTTPSPA.post(`ZaingetPinCode.php?msisdn=966${ this.number }&serviceId=3595`).then((res) => {
                if (res.data.status == 1 && res.data.message == '000000,The operation succeeds.') {
                  this.$cookie.setCookie("mdn_Otp", "966"+this.number, { expire: 60 * 60 });
                  this.$cookie.setCookie("operator", "zain", { expire: 60 * 60 });
                  toast.success("تم ارسال رمز التاكد بنجاح");
                  setTimeout(() => this.$router.push({ name: "Otp" }), 2500);
                } else if (res.data.status == 1 && res.data.message == '-106,Reached maximum daily limit of VCode requests') {
                  toast.info("تم الوصول إلى الحد الأقصى اليومي لطلبات رمز التاكيد");
                  setTimeout(() => this.$router.push({ path: "/" }), 2500);
                  this.$cookie.setCookie("mdn_Otp", "966"+this.number, { expire: 60 * 60 });
                } else if (res.data.status == 1 && res.data.message == '-70') {
                  toast.success("المستخدم مشترك بالفعل");
                  this.$cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 * 7 });
                  this.$cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 * 7 });
                  this.$cookie.setCookie("STC", 'unSub', { expire: 60 * 60 * 24 * 7 });
                  setTimeout(() => this.$router.push({ path: "/" }), 2500);
                } else {
                  toast.error("خطأ داخلي في الخدمة");
                }
          }).catch((error) => {
                console.log(error);
          }).finally(() => {
            this.loading = false;
          });
        } else if (this.number.substring(2, 1) === '4') {
          // Mobily api call to sent otp
          // console.log("mobilyyyyyyyy")
          this.loading = true;
          await HTTPSPA.post(`MobilygetPinCode.php?msisdn=966${ this.number }&serviceId=3596`).then((res) => {
            if (res.data.status == 1 && res.data.message == '000000,Operation succeeded.') {
                  this.$cookie.setCookie("mdn_Otp", "966"+this.number, { expire: 60 * 60 });
                  this.$cookie.setCookie("operator", "mobily", { expire: 60 * 60 });
                  toast.success("تم ارسال رمز التاكد بنجاح");
                  setTimeout(() => this.$router.push({ name: "Otp" }), 2500);
                } else if (res.data.status == 1 && res.data.message == '-70') {
                  toast.success("المستخدم مشترك بالفعل");
                  this.$cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 * 7 });
                  this.$cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 * 7 });
                  setTimeout(() => this.$router.push({ path: "/" }), 2500);
                } else {
                  toast.error("خطأ داخلي في الخدمة");
                }
          }).catch((error) => {
            console.log(error);
          }).finally(() => {
            this.loading = false;
          });
        } else if (this.number.substring(2, 1) === '3' || this.number.substring(2, 1) === '5' || this.number.substring(2, 1) === '0') {
          // STC api call to sent otp
          // console.log("stcccccccc")
          // this.loading = true;
          // await HTTPSPA.post(`StcSubscribtion.php?msisdn=966${ this.number }&serviceId=3754`).then((res) => {
          //       if (res.data.status == 1 && res.data.message == 3000) {
          //         this.$cookie.setCookie("mdn_Otp", "966"+this.number, { expire: 60 * 60 });
          //         this.$cookie.setCookie("operator", "stc", { expire: 60 * 60 });
          //         toast.success("تم ارسال رمز التاكد بنجاح");
          //         setTimeout(() => this.$router.push({ name: "Otp" }), 2500);
          //       } else if (res.data.status == 1 && res.data.message == '-106,Reached maximum daily limit of VCode requests') {
          //         toast.info("تم الوصول إلى الحد الأقصى اليومي لطلبات رمز التاكيد");
          //         setTimeout(() => this.$router.push({ path: "/" }), 2500);
          //         this.$cookie.setCookie("mdn_Otp", "966"+this.number, { expire: 60 * 60 });
          //       } else if (res.data.status == 1 && res.data.message == '-70') {
          //         toast.success("المستخدم مشترك بالفعل");
          //         // cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 * 7 });
          //         // cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 * 7 });
          //         setTimeout(() => this.$router.push({ path: "/" }), 2500);
          //       } else {
          //         // toast.error("خطأ داخلي في الخدمة");
          //         toast.info("اشترك عن طريق ارسال 3 في رسالة نصية الي الرقم 802043");
          //         setTimeout(() => this.$router.push({ name: "StcSub" }), 3000);
          //       }
          // }).catch((error) => {
          //   console.log(error);
          // }).finally(() => {
          //   this.loading = false;
          // });
          toast.info("اشترك عن طريق ارسال 3 في رسالة نصية الي الرقم 802043");
          setTimeout(() => this.$router.push({ name: "StcSub" }), 3000);
        }
      }
      }
    }
  // setup() {
  //   const router = useRouter();
  //   const route = useRoute();
  //   const cookie = useCookie();
  //   const toast = useToast();
  //   const msisdn = ref('');
  //   const loading = ref(false);
  //   var number;

  //   const onLogin = async () => {
  //     const Regex =  new RegExp(/^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/);
  //     if (msisdn.value === '') {
  //         toast.error("لا يمكن ترك حقل رقم الهاتف فارغًا");
  //     } else if (!Regex.test(msisdn.value)) {
  //         toast.error("رقم الهاتف غير صحيح");
  //     } else {
  //       if (msisdn.value.substring(0, 1) === "0" && msisdn.value.length === 10) {
  //         number = msisdn.value.substring(1);
  //       } else if (msisdn.value.substring(0, 1) === "5" && msisdn.value.length === 9) {
  //         number = msisdn.value.substring();
  //       } else if (msisdn.value.substring(0, 1) === "9" && msisdn.value.length === 12) {
  //         number = msisdn.value.substring(3);
  //       } else if (msisdn.value.substring(0, 1) === "0" && msisdn.value.length === 14) {
  //         number = msisdn.value.substr(5);
  //       }
  //       if (number.substring(2, 1) === '9') {
  //         // Zain api call to sent otp
  //         // console.log("zainnnnnnnnnnnnnnnnn")
  //         loading.value = true;
  //         await HTTPSPA.post(`ZaingetPinCode.php?msisdn=966${ number }&serviceId=3595`).then((res) => {
  //           if (res.data.status == 1 && res.data.message == '000000,The operation succeeds.') {
  //                 cookie.setCookie("mdn_Otp", "966"+number, { expire: 60 * 60 });
  //                 cookie.setCookie("operator", "zain", { expire: 60 * 60 });
  //                 toast.success("تم ارسال رمز التاكد بنجاح");
  //                 setTimeout(() => router.push({ name: "Otp" }), 2500);
  //               } else if (res.data.status == 1 && res.data.message == '-106,Reached maximum daily limit of VCode requests') {
  //                 toast.info("تم الوصول إلى الحد الأقصى اليومي لطلبات رمز التاكيد");
  //                 setTimeout(() => router.push({ path: "/" }), 2500);
  //                 cookie.setCookie("mdn_Otp", "966"+number, { expire: 60 * 60 });
  //               } else if (res.data.status == 1 && res.data.message == '-70') {
  //                 toast.success("المستخدم مشترك بالفعل");
  //                 cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 * 7 });
  //                 cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 * 7 });
  //                 cookie.setCookie("STC", 'unSub', { expire: 60 * 60 * 24 * 7 });
  //                 setTimeout(() => router.push({ path: "/" }), 2500);
  //               } else {
  //                 toast.error("خطأ داخلي في الخدمة");
  //               }
  //         }).catch((error) => {
  //               console.log(error);
  //         }).finally(() => {
  //               loading.value = false;
  //         });
  //       } else if (number.substring(2, 1) === '4') {
  //         // Mobily api call to sent otp
  //         // console.log("mobilyyyyyyyy")
  //         loading.value = true;
  //         await HTTPSPA.post(`MobilygetPinCode.php?msisdn=966${ number }&serviceId=3596`).then((res) => {
  //           if (res.data.status == 1 && res.data.message == '000000,Operation succeeded.') {
  //                 cookie.setCookie("mdn_Otp", "966"+number, { expire: 60 * 60 });
  //                 cookie.setCookie("operator", "mobily", { expire: 60 * 60 });
  //                 toast.success("تم ارسال رمز التاكد بنجاح");
  //                 setTimeout(() => router.push({ name: "Otp" }), 2500);
  //               } else if (res.data.status == 1 && res.data.message == '-70') {
  //                 toast.success("المستخدم مشترك بالفعل");
  //                 cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 * 7 });
  //                 cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 * 7 });
  //                 setTimeout(() => router.push({ path: "/" }), 2500);
  //               } else {
  //                 toast.error("خطأ داخلي في الخدمة");
  //               }
  //         }).catch((error) => {
  //               console.log(error);
  //         }).finally(() => {
  //               loading.value = false;
  //         });
  //       } else if (number.substring(2, 1) === '3' || number.substring(2, 1) === '5' || number.substring(2, 1) === '0') {
  //         // STC api call to sent otp
  //         // console.log("stcccccccc")
  //         loading.value = true;
  //         await HTTPSPA.post(`StcSubscribtion.php?msisdn=966${ number }&serviceId=3754`).then((res) => {
  //           if (res.data.status == 1 && res.data.message == 3000) {
  //                 cookie.setCookie("mdn_Otp", "966"+number, { expire: 60 * 60 });
  //                 cookie.setCookie("operator", "stc", { expire: 60 * 60 });
  //                 toast.success("تم ارسال رمز التاكد بنجاح");
  //                 setTimeout(() => router.push({ name: "Otp" }), 2500);
  //               } else if (res.data.status == 1 && res.data.message == '-106,Reached maximum daily limit of VCode requests') {
  //                 toast.info("تم الوصول إلى الحد الأقصى اليومي لطلبات رمز التاكيد");
  //                 setTimeout(() => router.push({ path: "/" }), 2500);
  //                 cookie.setCookie("mdn_Otp", "966"+number, { expire: 60 * 60 });
  //               } else if (res.data.status == 1 && res.data.message == '-70') {
  //                 toast.success("المستخدم مشترك بالفعل");
  //                 // cookie.setCookie("msisdn", res.data.msisdn, { expire: 60 * 60 * 24 * 7 });
  //                 // cookie.setCookie("status", res.data.status, { expire: 60 * 60 * 24 * 7 });
  //                 setTimeout(() => router.push({ path: "/" }), 2500);
  //               } else {
  //                 toast.error("خطأ داخلي في الخدمة");
  //               }
  //         }).catch((error) => {
  //               console.log(error);
  //         }).finally(() => {
  //               loading.value = false;
  //         });
  //       }
  //     }
  //   }
  //   return {msisdn, onLogin, loading}
  // },
}
</script>

<style scoped>
.btn-warning {
  color: #fff;
  border-color: rgb(131, 203, 206);
  background: #315b10 none repeat scroll 0% 0% !important
}

.bg-dark {
  background-image: linear-gradient(0deg,#315b10 0,#67bb27 );
}
</style>